// src/containers/Home.js
import React, { useState } from 'react';
import { Game } from '../../components/Game';

export const Home = () => {
    const [showGame, setShowGame] = useState(false);
    const [inputCode, setInputCode] = useState('');
    const [error, setError] = useState('');

    const correctCode = '99779977';

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputCode === correctCode) {
            setShowGame(true);
        } else {
            setError('Incorrect code. Please try again.');
            setInputCode('');
        }
    };

    if (showGame) {
        return <Game />;
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900">
            <form
                onSubmit={handleSubmit}
                className="bg-gray-800 p-8 rounded-lg shadow-lg"
            >
                <h2 className="text-2xl text-white mb-6 text-center">
                    Enter Access Code
                </h2>

                <input
                    type="password"
                    value={inputCode}
                    onChange={(e) => {
                        setError('');
                        setInputCode(e.target.value.replace(/[^0-9]/g, '').slice(0, 8));
                    }}
                    className="w-full px-4 py-2 mb-4 text-xl text-center tracking-widest bg-gray-700 text-black rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="********"
                    maxLength={8}
                />

                {error && (
                    <p className="text-red-500 text-sm mb-4 text-center">
                        {error}
                    </p>
                )}

                <button
                    type="submit"
                    className="w-full py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    Submit
                </button>
            </form>
        </div>
    );
};
