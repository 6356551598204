import Phaser from 'phaser';
import pink_bg_001 from '../../assets/images/pink_bg_001.png';
import pink_bg_002 from '../../assets/images/pink_bg_002.png';
//audio
import backgroundMusic from '../../assets/audio/big-trance-by-kris-klavenes-161372.mp3';
//player
import playerSprite from '../../assets/images/pink_run_002.png';
import pink001 from '../../assets/images/pink_001.png';
import pink002 from '../../assets/images/pink_002.png';
import pink003 from '../../assets/images/pink_003.png';
import pink004 from '../../assets/images/pink_004.png';
import pink005 from '../../assets/images/pink_005.png';
import pink006 from '../../assets/images/pink_006.png';
import drone_001 from '../../assets/images/drone_001.png';
import ship_001 from '../../assets/images/ship_001.png';
import ship_002 from '../../assets/images/ship_002.png';

export default class PinkGameScene extends Phaser.Scene {
    constructor() {
        super('PinkGameScene');
        this.scrollSpeed = 2;
        this.gameWidth = window.innerWidth * 0.75;
        this.gameHeight = 600;
        //this.totalScroll = 0; // Track total scroll distance
        this.isMusicPlaying = true;
        //this.drones = [];  // Array to store drone sprites
        this.enemies = [];  // Renamed from drones to enemies to be more generic
        this.enemySpawnTime = 2000;
        //this.droneSpawnTime = 2000; // Spawn a new drone every 2 seconds
        // Bind the spawnEnemy method to maintain correct context
        this.spawnEnemy = this.spawnEnemy.bind(this);
    }

    preload() {
        this.load.image('drone', drone_001);
        this.load.image('ship', ship_001);
        this.load.image('ship2', ship_002);

        this.load.image('pinkBackground1', pink_bg_001);
        this.load.image('pinkBackground2', pink_bg_002);

        // Load the audio using the imported file
        this.load.audio('background-music', backgroundMusic);

        // Load the sprite image
        this.load.image('player', playerSprite);

        // Load all frames
        this.load.image('pink1', pink001);
        this.load.image('pink2', pink002);
        this.load.image('pink3', pink003);
        this.load.image('pink4', pink004);
        this.load.image('pink5', pink005);
        this.load.image('pink6', pink006);
    }

    create() {
        // Create three background sprites
        this.background1 = this.add.sprite(0, 0, 'pinkBackground1');
        this.background2 = this.add.sprite(0, 0, 'pinkBackground2');
        this.background3 = this.add.sprite(0, 0, 'pinkBackground1');

        // Set the origin to top-left corner
        this.background1.setOrigin(0, 0);
        this.background2.setOrigin(0, 0);
        this.background3.setOrigin(0, 0);

        // Scale backgrounds to fit game height
        const scaleY = this.gameHeight / this.background1.height;
        this.background1.setScale(scaleY);
        this.background2.setScale(scaleY);
        this.background3.setScale(scaleY);

        // Calculate the scaled width
        this.bgWidth = Math.ceil(this.background1.width * scaleY);

        // Initial positioning
        this.background1.x = 0;
        this.background2.x = this.bgWidth;
        this.background3.x = this.bgWidth * 2;

        // Store the backgrounds in an array
        this.backgrounds = [this.background1, this.background2, this.background3];

        // Use this
        this.ground = this.add.rectangle(
            this.game.config.width / 2,  // x position - center of screen
            550,                         // y position
            this.game.config.width,      // full width of game screen
            40,                          // height
            0x00ff00                    // color
        );
        // Make it invisible but keep collision functionality
       this.ground.setAlpha(0);

        this.physics.add.existing(this.ground, true);

        // Set up cursor keys
        this.cursors = this.input.keyboard.createCursorKeys();

        // Add the music button in the top-right corner
        const musicButton = this.add.text(750, 20, '🔊', {
            fontSize: '32px',
            padding: { x: 10, y: 5 },
            backgroundColor: '#000000',
            color: '#ffffff'
        })
            .setOrigin(1, 0)
            .setInteractive({ useHandCursor: true })
            .setScrollFactor(0); // This keeps it fixed on screen

        // Play the background music
        this.backgroundMusic = this.sound.add('background-music', {
            volume: 0.5,
            loop: true
        });
        this.backgroundMusic.play();

        // Add click handler for the music button
        musicButton.on('pointerdown', () => {
            if (this.isMusicPlaying) {
                this.backgroundMusic.pause();
                musicButton.setText('🔈');
            } else {
                this.backgroundMusic.resume();
                musicButton.setText('🔊');
            }
            this.isMusicPlaying = !this.isMusicPlaying;
        });

// Optional hover effects
        musicButton.on('pointerover', () => {
            musicButton.setStyle({ backgroundColor: '#333333' });
        });

        musicButton.on('pointerout', () => {
            musicButton.setStyle({ backgroundColor: '#000000' });
        });

        // Create the animation
        this.anims.create({
            key: 'fly-right',
            frames: [
                { key: 'pink1' },
                { key: 'pink2' },
                { key: 'pink3' },
                { key: 'pink4' },
                { key: 'pink5' },
                { key: 'pink6' }
            ],
            frameRate: 10, // Adjust this number to make animation faster/slower
            repeat: 0    // -1 means loop forever
        });

        // Create the player sprite
        this.player = this.physics.add.sprite(400, 300, 'pink1');

        // Flying physics setup
        //this.player.body.setGravityY(0);  // Remove gravity
        //this.player.body.setDrag(100, 100);  // Add drag for smoother movement

        this.player.setScale(0.4);
        // Important: Set the ground's Y position as a constant
        const GROUND_Y = 530;

        // Track animation state
        this.isFlying = false;

        // Enable physics on the player
        this.player.setBounce(0.2);
        // Make sure you have these lines as well:
        this.physics.add.collider(this.player, this.ground);
        this.player.setCollideWorldBounds(true);
        this.player.body.setBoundsRectangle(new Phaser.Geom.Rectangle(
            0,          // x
            0,          // y
            this.game.config.width,  // width
            GROUND_Y    // height - stops at ground level
        ));

        // Set up gravity
        this.player.body.setGravityY(300);

        // Set up collision between player and ground
        this.physics.add.collider(this.player, this.ground);

        // Set up cursor keys
        this.cursors = this.input.keyboard.createCursorKeys();


        // In your cursor key handling code, play the animation when moving right
        if (this.cursors.right.isDown) {
            this.player.play('fly-right', true);  // true means play even if already playing
            // Your existing right movement code...
        } else {
            this.player.stop();  // Stop the animation when not moving
            this.player.setTexture('pink1');  // Reset to standing frame
        }

        // Initialize enemies array if it somehow got cleared
        if (!this.enemies) {
            this.enemies = [];
        }

        // Start spawning drones
        this.time.addEvent({
            delay: this.enemySpawnTime,
            callback: this.spawnEnemy,
            callbackScope: this,
            loop: true
        });

        // Enable physics for player if not already done
        this.physics.add.existing(this.player);



    }

    // Add this method to randomly choose between drone and ship
    spawnEnemy() {
        const GROUND_LEVEL = 530;
        // Randomly decide between three enemy types (0: drone, 1: ship, 2: ship2)
        const enemyType = Phaser.Math.Between(0, 2);

        const x = this.gameWidth + 400;
        const y = Phaser.Math.Between(100, GROUND_LEVEL - 100);

        let enemy;
        switch (enemyType) {
            case 1: // ship
                enemy = this.physics.add.sprite(x, y, 'ship');
                enemy.type = 'ship';
                enemy.setScale(0.4);
                enemy.speed = 4; // Ships might be faster
                break;
            case 2: // ship2
                enemy = this.physics.add.sprite(x, y, 'ship2'); // Make sure 'ship2' is loaded in your preload function
                enemy.type = 'ship2';
                enemy.setScale(0.4); // Adjust scale as needed
                enemy.speed = 5; // Make ship2 even faster
                break;
            default: // drone (case 0)
                enemy = this.physics.add.sprite(x, y, 'drone');
                enemy.type = 'drone';
                enemy.setScale(0.25);
                enemy.speed = 2; // Drones might be slower
                break;
        }

        enemy.body.setAllowGravity(false);
        enemy.setVisible(true);
        enemy.setVelocityX(-150);  // Move left

        // Add collision with player
        this.physics.add.overlap(this.player, enemy, this.handleDroneCollision, null, this);

        // Make sure we're adding to the array
        if (!this.enemies) {
            this.enemies = [];
        }
        this.enemies.push(enemy);
    }


    handleDroneCollision(player, drone) {
        // Create explosion effect (you can enhance this)
        const explosion = this.add.circle(
            drone.x,
            drone.y,
            20,
            0xff0000
        );

        // Fade out explosion
        this.tweens.add({
            targets: explosion,
            alpha: 0,
            duration: 500,
            ease: 'Power2',
            onComplete: () => {
                explosion.destroy();
            }
        });

        // Remove the drone
        drone.destroy();

        // Remove player and end game
        player.destroy();

        // Add game over text
        // eslint-disable-next-line
        const gameOverText = this.add.text(
            this.game.config.width / 2,
            this.game.config.height / 2,
            'Game Over!',
            {
                fontSize: '64px',
                fill: '#FF0000'
            }
        ).setOrigin(0.5);

        // Optional: Add restart button
        const restartButton = this.add.text(
            this.game.config.width / 2,
            this.game.config.height / 2 + 80,
            'Click to Restart',
            {
                fontSize: '32px',
                fill: '#FFFFFF'
            }
        )
            .setOrigin(0.5)
            .setInteractive();

        restartButton.on('pointerdown', () => {
            this.backgroundMusic.stop();
            this.isMusicPlaying = !this.isMusicPlaying;
            this.scene.restart();
        });
    }

    update() {
        // Handle player movement
       // let isMoving = false;  // Track if player is moving

        // Optional: Ensure drones keep moving left
        /*8this.drones.forEach((drone) => {
            if (drone.active) {
                drone.setVelocityX(-150); // Maintain left movement

                // Clean up drones that are off screen
                if (drone.x < -100) {
                    drone.destroy();
                    this.drones = this.drones.filter(d => d !== drone);
                }
            }
        });*/

        // Use filter instead of splice for safer array modification
        if (this.enemies) {
            this.enemies = this.enemies.filter(enemy => {
                if (enemy) {
                    enemy.x -= enemy.speed;
                    if (enemy.x < -enemy.width) {
                        enemy.destroy();
                        return false;
                    }
                    return true;
                }
                return false;
            });
        }

        // Only process player controls if player exists
        if (this.player && this.player.active) {
            // Handle player movement
            if (this.cursors.left.isDown) {
                this.player.setVelocityX(-160); // Move left
                if (!this.isFlying) {
                    this.player.play('fly-right');
                    this.isFlying = true;
                }
                this.player.setFlipX(true);
               // isMoving = true;
            } else if (this.cursors.right.isDown) {
                this.player.setVelocityX(160);  // Move right
                if (!this.isFlying) {
                    this.player.play('fly-right');
                    this.isFlying = true;
                }
                this.player.setFlipX(false);
              //  isMoving = true;
            } else {
                this.player.setVelocityX(0);    // Stop horizontal movement
                this.player.setTexture('pink1');
                this.isFlying = false;
            }

            // Jump only if touching the ground
            if (this.cursors.up.isDown && this.player.velocity !== -400) {
                this.player.setVelocityY(-400); // Jump velocity
            }
        }

        if (this.player && this.player.active) {
            // Only scroll backgrounds if player is moving right
            if (this.cursors.right.isDown) {
                this.backgrounds.forEach((bg) => {
                    bg.x -= this.scrollSpeed;

                    if (bg.x <= -this.bgWidth) {
                        // Find rightmost background
                        let rightmostX = -Infinity;
                        let rightmostBg = null;

                        this.backgrounds.forEach(bgCheck => {
                            if (bgCheck.x > rightmostX) {
                                rightmostX = bgCheck.x;
                                rightmostBg = bgCheck;
                            }
                        });

                        // Position after rightmost background
                        bg.x = rightmostX + this.bgWidth - this.scrollSpeed;

                        // Set correct alternating texture
                        if (rightmostBg.texture.key === 'pinkBackground1') {
                            bg.setTexture('pinkBackground2');
                        } else {
                            bg.setTexture('pinkBackground1');
                        }
                    }
                });
            }
        }
        // Scroll backgrounds left if player is moving left
        /*else if (this.cursors.left.isDown) {
            this.backgrounds.forEach((bg) => {
                bg.x += this.scrollSpeed;

                if (bg.x >= this.game.config.width) {
                    // Find leftmost background
                    let leftmostX = Infinity;
                    let leftmostBg = null;

                    this.backgrounds.forEach(bgCheck => {
                        if (bgCheck.x < leftmostX) {
                            leftmostX = bgCheck.x;
                            leftmostBg = bgCheck;
                        }
                    });

                    // Position before leftmost background
                    bg.x = leftmostX - this.bgWidth - this.scrollSpeed;

                    // Set correct alternating texture
                    if (leftmostBg.texture.key === 'pinkBackground1') {
                        bg.setTexture('pinkBackground2');
                    } else {
                        bg.setTexture('pinkBackground1');
                    }
                }
            });
        }*/

        // Additional safety check in update
        /*if (this.player.y > 550) {
            this.player.y = 550;
            this.player.body.velocity.y = 0;
        }*/


    }
}
