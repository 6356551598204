/**
 * Created by andyosullivan on 15/06/2024
 */

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home } from './containers/Home/Home';

function NewRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
        </Routes>
    );
}

export default NewRoutes;





